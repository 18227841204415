import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="text-center">
      <div className="min-h-screen flex justify-center items-center">
        <div className="max-w-sm">
          <h1>404: Not Found</h1>
          <p>This page does not exist.</p>
          <p>
            Go back to the{' '}
            <Link
              to="/"
              className="underline hover:no-underline hover:text-black"
              activeClassName="active"
            >
              Projects
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
